<template>
  <div class="innovation">
    <Brand />
    <BackBtn
        @click="back()"
        title="Zum Prozessschritt" />
    <div class="subnav-container">
      <h1
          :class="{ active: state.section === 'description' }"
          @click="toggleContent('description')">
        {{ state.innovation.title }}
        <b>- Beschreibung</b>
        <div
            class="touchpoint"
            v-if="state.section !== 'description'">
          <TouchpointSimple @click="toggleContent('description')" />
        </div>
      </h1>
      <ul class="subnav">
        <li :class="{ active: state.section === 'fields' }">
          <span class="id" v-text="1" />
          <span class="title">
            <b>Anwendung</b>
          </span>
          <div
              class="touchpoint"
              v-if="state.section !== 'fields'">
            <TouchpointSimple @click="toggleContent('fields')" />
          </div>
        </li>
        <li :class="{ active: state.section === 'data' }">
          <span class="id" v-text="2" />
          <span class="title">
            <b>Datenstrom</b>
          </span>
          <div
              class="touchpoint"
              v-if="state.section !== 'data'">
            <TouchpointSimple @click="toggleContent('data')" />
          </div>
        </li>
        <li :class="{ active: state.section === 'integration' }">
          <span class="id" v-text="3" />
          <span class="title">
            <b>Integration</b>
          </span>
          <div
              class="touchpoint"
              v-if="state.section !== 'integration'">
            <TouchpointSimple @click="toggleContent('integration')" />
          </div>
        </li>
        <li :class="{ active: state.section === 'chances' }">
          <span class="id" v-text="4" />
          <span class="title">
            <b>Chancen</b>
          </span>
          <div
              class="touchpoint"
              v-if="state.section !== 'chances'">
            <TouchpointSimple @click="toggleContent('chances')" />
          </div>
        </li>
        <li :class="{ active: state.section === 'costs' }">
          <span class="id" v-text="5" />
          <span class="title">
            <b>Kosten</b>
          </span>
          <div
              class="touchpoint"
              v-if="state.section !== 'costs'">
            <TouchpointSimple @click="toggleContent('costs')" />
          </div>
        </li>
        <li
            :class="{ active: state.section === 'products' }"
            v-if="route.query.products === '1' && state.innovation.products && state.innovation.products.length > 0">
          <span class="id" v-text="6" />
          <span class="title">
            <b>Produkte</b>
          </span>
          <div
              class="touchpoint"
              v-if="state.section !== 'products'">
            <TouchpointSimple @click="toggleContent('products')" />
          </div>
        </li>
      </ul>
      <transition
          enter-active-class="animated slideInUp"
          leave-active-class="animated slideOutDown"
          mode="out-in"
          appear>
        <div class="content">
          <div
              class="image"
              :style="{
                backgroundImage: state.innovation.image
                  ? `url(${state.basePath}images/${state.innovation.image})`
                  : `url(${require('@/assets/img/placeholder.png')})`
              }" />
          <transition
              name="slideRight"
              mode="out-in">
            <div
                class="body"
                :key="state.section">
              <div
                  class="description"
                  v-if="state.section === 'description'">
                <h2>Beschreibung</h2>
                <div v-html="state.innovation.description" />
                <template v-if="state.innovation.additionalLinks">
                <h2 v-if="state.innovation.additionalLinks.length > 0">
                  Weiterführende Links
                </h2>
                <ul
                    v-if="state.innovation.additionalLinks.length > 0"
                    class="link-list">
                  <li
                      v-for="(link, idx) in state.innovation.additionalLinks"
                      :key="idx">
                    <a :href="link" target="_blank">{{ link }}</a>
                  </li>
                </ul>
                </template>
              </div>
              <div
                  class="fields"
                  v-if="state.section === 'fields'">
                <h2>Anwendungsfelder</h2>
                <div v-html="state.innovation.fields" />
              </div>
              <div
                  class="data"
                  v-if="state.section === 'data'">
                <h2>Datenstrom</h2>
                <div v-html="state.innovation.data" />
              </div>
              <div
                  class="integration"
                  v-if="state.section === 'integration'">
                <h2>Integration in das Autohaus</h2>
                <div v-html="state.innovation.integration" />
              </div>
              <div
                  class="chances"
                  v-if="state.section === 'chances'">
                <h2>Chancen</h2>
                <div v-html="state.innovation.chances" />
              </div>
              <div
                  class="costs"
                  v-if="state.section === 'costs'">
                <h2>Kosten</h2>
                <div v-html="state.innovation.costs" />
              </div>
              <div
                  class="products"
                  v-if="route.query.products === '1' && state.section === 'products'">
                <h2>
                  Neugierig geworden?
                  <br>
                  <small>
                    Jetzt Lösungen ({{ state.innovation.products.length }})
                    unserer Innovationspartner ausprobieren
                  </small>
                </h2>
                <ul>
                  <transition-group
                      name="fade"
                      appear>
                    <li
                        v-for="(product, idx) in state.innovation.products"
                        :key="product.id"
                        :style="{ animationDelay: `${idx * 0.25}s` }"
                        @click="showOverlay(product.id)">
                      <div class="img-container">
                        <img
                          :src="`${state.basePath}images/${product.logo}`"
                          :alt="product.title"
                          v-if="product.logo">
                        <img
                            src="../assets/img/placeholder-light.png"
                            :alt="product.title"
                            v-if="!product.logo">
                      </div>
                      <span v-if="false" class="id" v-text="idx + 1" />
                      <span class="title" v-text="product.title" />
                    </li>
                  </transition-group>
                </ul>
              </div>
            </div>
          </transition>
        </div>
      </transition>
    </div>
    <Overlay
        v-if="route.query.products === '1' && state.overlay"
        :id="state.pid"
        @close="closeOverlay" />
  </div>
</template>


<script>
import { onMounted, getCurrentInstance, reactive } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import Brand from '@/components/Brand'
import BackBtn from '@/components/BackBtn'
import TouchpointSimple from '@/components/TouchpointSimple'
import Overlay from '@/components/Overlay'

export default {
  name: 'Autohaus',

  components: {
    Brand,
    BackBtn,
    TouchpointSimple,
    Overlay
  },

  setup () {
    const instance = getCurrentInstance()
    const emitter  = instance.appContext.config.globalProperties.emitter
    const axios    = instance.appContext.config.globalProperties.axios
    const router   = useRouter()
    const route    = useRoute()

    const state = reactive({
      publicPath: process.env.BASE_URL,
      basePath: process.env.VUE_APP_API_ENDPOINT,
      innovation: {},
      section: 'description',
      pid: null,
      overlay: false
    })

    const getProcess = async (iid) => {
      emitter.emit('loading-start')
      await axios
        .all([
          axios.get(`${process.env.VUE_APP_API_ENDPOINT}products`),
          axios.get(`${process.env.VUE_APP_API_ENDPOINT}innovations/${iid}`)
        ])
        .then(axios.spread((...responses) => {
          state.innovation = responses[1].data
          state.innovation.products =
            responses[0].data
              .filter(product => responses[1].data.products.includes(product.id))
          emitter.emit('loading-stop')
      }))
    }

    const back = () => {
      emitter.emit('play-btn-sound')
      router.push({
        name: 'Prozessschritt',
        params: {
          section: route.params.section,
          pid: route.params.pid
        },
        query: {
          processBack: route.query.processBack,
          products: route.query.products,
          ifa: route.query.ifa
        }
      })
    }

    const toggleContent = (section) => {
      emitter.emit('play-btn-sound')
      state.section = section
    }

    const showOverlay = (pid) => {
      state.pid = parseInt(pid)
      state.overlay = true
    }

    const closeOverlay = () => {
      state.pid = null
      state.overlay = false
    }

    onMounted(() => getProcess(route.params.iid))

    return {
      back,
      route,
      state,
      toggleContent,
      showOverlay,
      closeOverlay
    }
  }
}
</script>


<style lang="scss" scoped>
.innovation {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-image: linear-gradient(to top, #fff 0%, #85a7c2 100%);
  background: url('/static/img/bg-innovation.jpg') no-repeat center center;
  background-size: cover;

  .subnav-container {
    position: absolute;
    left: 0;
    top: 12vh;
    width: 100%;
    height: calc(100% - 12vh);
    display: flex;
    flex-direction: column;

    > h1 {
      width: 100%;
      background: rgba($color: #5b6e7f, $alpha: 0.95);
      color: #fff;
      padding: 1.5rem 1.5rem 1rem 1.5rem;
      border-bottom: 1px solid rgba($color: #fff, $alpha: 0.25);
      letter-spacing: -0.5pt;
      transition: all 250ms ease-in-out;
      cursor: pointer;

      b {
        margin-right: 1rem;
        font-weight: 200;
      }

      &.active {
        background: rgba($color: #333, $alpha: 0.95);
        box-shadow: 0 0 2rem rgba($color: #000, $alpha: 0.2);
      }

      .touchpoint {
        transition: all 500ms ease-in-out;
        position: absolute;
        top: -1.25rem;
        left: calc(50% - 1.25rem);

        &:hover {
          transform: scale(1.5);
        }
      }
    }

    .subnav {
      display: flex;
      list-style: none;
      padding: 0;
      margin: 0;
      width: 100%;
      height: auto;
      position: relative;
      z-index: 10;

      li {
        width: 100%;
        background: rgba($color: #5b6e7f, $alpha: 0.95);
        color: #fff;
        padding: 1.5rem 1.5rem 2.5rem 1.5rem;
        border-right: 1px solid rgba($color: #fff, $alpha: 0.25);
        border-bottom: 1px solid rgba($color: #fff, $alpha: 0.25);
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        transition: all 500ms ease-in-out;

        &:last-child {
          border-right: none;
        }

        &.active {
          background: rgba($color: #333, $alpha: 0.95);
          box-shadow: 0 0 2rem rgba($color: #000, $alpha: 0.2);
          z-index: 10;
        }

        span {
          height: 100%;
          text-align: center;
        }

        .id {
          position: absolute;
          font-family: 'Navada Outline', sans-serif;
          font-size: 5rem;
          height: 5rem;
          line-height: 1em;
          left: 15%;
          bottom: -0.045em;
          color: rgba($color: #fff, $alpha: 0.1);
        }

        .title {
          font-size: 1.1rem;
          font-weight: 600;

          small {
            font-weight: 400;
            color: #c1e2f1;
          }
        }

        .touchpoint {
          transition: all 500ms ease-in-out;
          position: absolute;
          bottom: -1.25rem;

          &:hover {
            transform: scale(1.5);
          }
        }
      }
    }

    .content {
      color: #fff;
      display: flex;
      overflow: hidden;
      height: 100%;
      line-height: 1.5rem;
      animation-duration: 350ms;
      background: linear-gradient(180deg, rgba($color: #5b6e7f, $alpha: 0.95) 0%, rgba($color: #415566, $alpha: 0.95));

      .image {
        width: 50%;
        height: 100%;
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
      }

      .body {
        width: 50%;
        height: 100%;
        padding: 4rem;
        overflow: auto;
        animation-duration: 350ms;

        h2 {
          margin: 4rem 0 1.5rem 0;
          font-size: 1.75rem;
          line-height: 1.25em;

          &:first-child {
            margin-top: 0;
          }

          small {
            display: block;
            font-weight: 400;
            font-size: 80%;
            color: #c1e2f1;
            line-height: 1.1em;
            margin-top: 0.25em;
            margin-bottom: 1.5em;
          }
        }

        ::v-deep() p,
        ::v-deep() ul {
          margin-bottom: 2em;

          span {
            color: #fff !important;
          }

          b {
            font-weight: 900;
          }
        }

        ::v-deep() ul {
          margin-left: 1.25rem;

          li {
            margin: 1rem 0;

            &::marker {
              font-size: 1.5rem;
              color: orange;
            }
          }
        }

        ::v-deep() sup {
          display: inline-block;
          font-size: 0.75rem;
          font-weight: 200;
          margin: -0.5em 0.25em 0 0.25em;
        }

        ::v-deep() a {
          color: rgb(181, 222, 226);

          &:hover {
            color: #fff;
          }
        }

        ::v-deep() .ql-size-small {
          display: block;
          font-size: 0.7rem;
          line-height: 1.25em;
          margin-bottom: -1.25rem;
          padding-bottom: 0.5rem;
          color: rgba($color: #fff, $alpha: 0.5) !important;
          border-bottom: 1px solid rgba($color: #fff, $alpha: 0.1);
        }

        .products {
          ul {
            padding: 0;
            margin: 0;
            list-style: none;
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-gap: 1rem;

            li {
              display: flex;
              flex-direction: column;
              margin: 0;
              padding: 0;
              min-width: 100%;
              overflow: hidden;
              border-bottom: 1px solid rgba($color: #fff, $alpha: 0.25);
              position: relative;
              cursor: pointer;
              transition: all 250ms ease-in-out;

              &:hover {
                opacity: 0.5;
              }

              .img-container {
                width: 100%;
                height: 0;
                display: block;
                clip-path: polygon(100% 0, 100% 90%, 90% 100%, 0 100%, 0 0);
                width: 100%;
                padding-top: 100%;
                background-color: #fff;
                overflow: hidden;
                position: relative;

                img {
                  position: absolute;
                  top: calc(50% - 2rem);
                  left: calc(50% - 2rem);
                  transform: translate(-50%, -50%);
                  max-width: calc(100% - 4rem);
                  max-height: calc(100% - 4rem);
                  margin: 2rem;
                }
              }

              .title {
                display: block;
                padding: 0.75rem 0;
                flex-grow: 1;
                line-height: 1.25rem;
              }

              .id {
                position: absolute;
                width: 2rem;
                height: 2rem;
                left: 1rem;
                top: 1rem;
                border-radius: 1rem;
                background-color: #333;
                color: #fff;
                display: flex;
                justify-content: center;
                align-items: center;
                font-weight: 900;
              }
            }
          }
        }
      }
    }
  }
}
</style>
